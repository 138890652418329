<template>
    <div class="d-flex mb-2">
        <a class="btn btn-sm btn-link" ref="downloadBtn">Download</a>
    </div>
    <div ref="qp_ref"></div>
</template>

<script setup>
import {loadCdnStyle,cdnBaseUrl} from 'o365.modules.helpers.js';
import { onMounted,ref } from 'vue';
const qp_ref = ref(null);
const props = defineProps(["xmlPlan"]);

const downloadBtn = ref(null);

loadCdnStyle(cdnBaseUrl+'/html-query-plan/2.6.1/css/qp.css');
onMounted(()=>{
        QP.showPlan(qp_ref.value, props.xmlPlan,{
            jsTooltips: false
        });

       try{
            const s = new XMLSerializer();
            const xmlString = s.serializeToString(parseXml(props.xmlPlan));
            const planBlob = new Blob([xmlString],{type: 'text/plain'});
            downloadBtn.value.href = window.URL.createObjectURL(planBlob);
            downloadBtn.value.download = "Query.sqlplan";
        }catch(ex){
            console.warn(ex);
        }

})

const parseXml = (xmlStr) =>{
    return ( new window.DOMParser() ).parseFromString(xmlStr, "text/xml");
};



</script>